@import "../../styles/Variable.scss";

.header {
  background-color: $white;
  // padding: 0px 60px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.10);
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 4;

  @media screen and (max-width: 767px) {
    padding: 0px 5px;
  }

  .logo {
    width: 150px;
  }

  a {
    color: $black;
    font-weight: 600;

    &:hover,
    &:focus {
      color: $secondary;
    }
  }

  ul {
    li {
      margin-right: 20px;
    }
  }
}

.header-profile {
  position: relative;

  &:hover {
    .user-dropdown {
      display: block;
    }
  }
}

.user-dropdown {
  display: none;
  position: absolute;
  top: 59px;
  right: -65px;
  padding: 0px 20px;
  border: 1px solid $primary;
  border-radius: 20px;
  list-style: none;
  background-color: $white;

  .dropdown-item {
    padding: 0px;
    font-weight: 500;
    cursor: pointer;
  }

  li {
    margin-right: 0px;
    padding: 5px 10px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);

    &:last-child {
      border: none;
    }

    a {
      &:hover {
        color: $black;
        background-color: $white;
      }
    }
  }
}

.header-padding {
  padding: 20px;


}

.user-box {
  position: relative;
  margin: 20px 20px 0px 20px;
  padding-bottom: 20px;

  span {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0px;
    right: -5px;

    svg {
      width: 22px;
      min-width: 22px;
      height: 22px;
    }
  }
}

.profile-status {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 5px;
  right: 10px;

  svg {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }
}